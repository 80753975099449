import React from 'react';

const Privacy = () => {
  return (
    <div className="bg-gray-100 py-10">
      <h2 className="text-2xl font-semibold text-center mb-5">PRIVACY & SECURITY</h2>

      <div className=" mx-auto max-w-2xl px-4">
        <p className="text-lg leading-loose mb-4">
          If you agree to our privacy agreement, we will notify the user of the latest product message by SMS or email.
        </p>

        <h3 className="text-xl font-medium mb-2">Welcome</h3>
        <p className="text-lg leading-loose mb-4">
          Welcome to our online store. Our goal is that you feel as comfortable using our website as you are shopping in your local mall. Whenever you purchase product(s) from our website, we take the issue of security seriously. Each time you enter your credit card number, your billing and shipping information when shopping, we will use the industry-standard Secure Sockets Layer (SSL) technology to prevent the information from being intercepted.
        </p>

        <h3 className="text-xl font-medium mb-2">Our Commitment To Privacy</h3>
        <p className="text-base leading-loose mb-4">
          Your privacy is important to us. To keep your privacy safer, we provide this notice, explaining our online information practices and choices that you can make about the way your information is collected and used. To make this notice easy to find, we make it available on our homepage and at every point where personally identifiable information may be requested.
        </p>

        <h3 className="text-xl font-medium mb-2">The Information We Collect</h3>
        <ul className="list-disc pl-4 space-y-2">
          <li>Name</li>
          <li>Address</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Credit/Debit Card Information (when placing an order)</li>
          <li>
            Recipient's name, address, and phone number (when ordering a gift to be shipped directly)
          </li>
        </ul>

        <h3 className="text-xl font-medium mb-2">The Way We Use Information</h3>
        <p className="text-base leading-loose mb-4">
          We use the information you provide for the following purposes:
        </p>
        <ul className="list-disc pl-4 space-y-2">
          <li>To complete your orders</li>
          <li>To ship products and confirm delivery (for gifts)</li>
          <li>To design and improve our website</li>
          <li>To share non-identifying and aggregate information with advertisers</li>
          <li>We never use or share your information for unrelated purposes without providing you an opportunity to opt-out.</li>
        </ul>

        <h3 className="text-xl font-medium mb-2">Our Commitment To Data Security</h3>
        <p className="text-base leading-loose mb-4">
          We have implemented various measures to safeguard your information:
        </p>
        <ul className="list-disc pl-4 space-y-2">
          <li>Physical procedures to restrict access to data centers</li>
          <li>Electronic safeguards to protect information in our computer systems</li>
          <li>Managerial procedures to ensure the misuse of information</li>
        </ul>

        <h3 className="text-xl font-medium mb-2">How You Can Access Or Correct Your Information</h3>
        <p className="text-base leading-loose mb-4">
          You can access and review your information or request corrections by sending a written request to the address listed on the Info page. We will take reasonable steps to verify your identity before granting access or making corrections.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
