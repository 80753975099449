import React from 'react'

const TermsAndCondition = () => {
  return (
        <section class="bg-white py-10 border-t border-gray-200">
          <h2 class="text-2xl font-semibold text-center mb-5">TERMS OF SERVICE</h2>

          <div class=" mx-auto max-w-2xl px-4">
            <h3 class="text-xl font-medium mb-2">Introduction</h3>
            <p class="text-lg leading-loose mb-4">
              These terms and conditions and any separate agreements whereby we provide you shall be governed by and construed in accordance with the laws of UK.
              By using this website, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.
            </p>

            <h3 class="text-xl font-medium mb-2">Amendments</h3>
            <p class="text-base leading-loose mb-4">
              We reserve the right to:
            </p>

            <ul class="list-disc pl-4 space-y-2">
              <li>
                Update these Terms from time to time or to interrupt the operation of the Site, or any portion of the Site, as necessary to perform routine or non-routine maintenance, error correction, or other changes. Any changes will be notified to you via a suitable announcement on the Website. It is your responsibility to check for such changes. The changes will apply to the use of the Website after we have given such an announcement. If you do not wish to accept the new Terms, you should not continue to use the Website. If you continue to use the Website after the date on which the change comes into effect, your use of the Website indicates your agreement to be bound by the new Terms; and modify or withdraw, temporarily or permanently, this Website and the material contained within (or any part) without notice to you and you confirm that we shall not be liable to you for any modification to or withdrawal of the Website or its contents.
              </li>
              </ul>
          </div>
        </section>
)}

export default TermsAndCondition