import React from 'react'
import styles from './TypeCard.module.css'
import { Link } from 'react-router-dom'

const CategoryCard = ({ data }) => {

    if(data.name.toLowerCase() ==='cigrette'){
        return (
            <Link to={`/cigretteBrands`}> 
            <div className={styles.mainCard}>
                <img src={data.img} alt="" className={styles.mainImg} loading='lazy' />
                <span className={styles.imgTitle}>{data.name}</span>
            </div>
        </Link>
        )
    }else if(data.name.toLowerCase() ==='vape'){
        return (
            <Link to={`/vapeBrands`}> 
            <div className={styles.mainCard}>
                <img src={data.img} alt="" className={styles.mainImg} loading='lazy' />
                <span className={styles.imgTitle}>{data.name}</span>
            </div>
        </Link>
        )
    }else if(data.name.toLowerCase() ==='tobacco'){
        return (
            <Link to={`/tobaccoBrands`}> 
            <div className={styles.mainCard}>
                <img src={data.img} alt="" className={styles.mainImg} loading='lazy' />
                <span className={styles.imgTitle}>{data.name}</span>
            </div>
        </Link>
        )
    }


    return (
        <Link to={`/product/type/${data.name.toLowerCase()}`}>
            <div className={styles.mainCard}>
                <img src={data.img} alt="" className={styles.mainImg} loading='lazy' />
                <span className={styles.imgTitle}>{data.name}</span>
            </div>
        </Link> 
    )
}
 
export default CategoryCard;