import React, { useEffect } from 'react'
import axios from 'axios'
import { Container } from '@mui/material'
// import { Box } from '@mui/system'
import { useContext } from 'react'
import { ContextFunction } from '../../Context/Context'
import CategoryCard from '../../Components/Category_Card/CategoryCard';
import BannerData from '../../Helpers/TobaccoPageBanner';
// import Carousel from '../../Components/Carousel/Carousel'
// import SearchBar from '../../Components/SearchBar/SearchBar'
import CopyRight from '../../Components/CopyRight/CopyRight'
import {Tobacco} from '../../Assets/Images/Image';


const TobaccoBrandPage = () => {
    const { setCart } = useContext(ContextFunction)
    let authToken = localStorage.getItem('Authorization')
    useEffect(() => {
        getCart()
        window.scroll(0, 0)
    }, [])
    const getCart = async () => {
        if (authToken !== null) {
            const { data } = await axios.get('https://wss-app-api.vercel.app/api/cart/fetchcart' ,//`${process.env.REACT_APP_GET_CART}`,
                {
                    headers: {
                        'Authorization': authToken
                    }
                })
            setCart(data);
        }

    }

    return (
        <>
            <Container maxWidth='xl' className='bg-gray-200' style={{ display: 'flex', justifyContent: "center", padding: 0, flexDirection: "column", marginBottom: 70 }}>
                {/* <Box padding={1}>
                    <Carousel />
                </Box> */}
                
                <div className="item" style={{ marginTop: 10 }} >
                    <img src={Tobacco} loading='lazy' alt='{item.name}' style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                </div>
                
                {/* <Container style={{ marginTop: 90, display: "flex", justifyContent: 'center' }}><SearchBar /></Container>
                <Typography variant='h3' sx={{ textAlign: 'center', marginTop: 10, color: '#1976d2', fontWeight: 'bold' }}>Categories</Typography> */}

                <Container maxWidth='xl' style={{ marginTop: 90, display: "flex", justifyContent: 'center', flexGrow: 1, flexWrap: 'wrap', gap: 20, }}>
                    { 
                        BannerData.map((data )=> (
                            <CategoryCard data={data}  key={data.img} />
                        ))
                    }
                </Container>
            </Container >
            <CopyRight className={'mt-8 mb-10'} />
        </>
    )
}

export default TobaccoBrandPage;