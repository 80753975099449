import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/Home/HomePage';
// import BrandsPage from './Pages/Home/BrandsPage.jsx';
import VapeBrandPage from './Pages/Home/VapeBrandPage.jsx';
import TobaccoBrandPage from './Pages/Home/TobaccoBrandPage.jsx';
import Login from './Auth/Login/Login';
import Register from './Auth/Register/Register';
import Cart from './Pages/Cart/Cart';
import ProductDetail from './Pages/Detail/ProductDetail';
import SingleCategory from './SingleCategory/SingleCategory';
import MobileNavigation from './Navigation/MobileNavigation';
import DesktopNavigation from './Navigation/DesktopNavigation';
import Wishlist from './Pages/WhisList/Wishlist';
import PaymentSuccess from './Pages/Payment/PaymentSuccess';
import { Flip, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CheckoutForm from './Components/Checkout/CheckoutForm';
import UpdateDetails from './Pages/Update_User/UpdateDetails';
import ForgotPasswordForm from './Auth/ForgotPassword/ForgotPasswordForm';
import AddNewPassword from './Auth/ForgotPassword/AddNewPassword';
import AdminLogin from './Admin/Auth/Login/AdminLogin';
import AdminRegister from './Admin/Auth/Register/AdminRegister';
import AdminHomePage from './Admin/Pages/AdminHomePage';
import SingleUserPage from './Admin/Pages/SingleUserPage';
import SingleProduct from './Admin/Pages/SingleProduct';
import Privacy from './Pages/Privacy/Privacy.js';
import TermsAndCondition from './Pages/Privacy/TermsAndCondition.js';
import ShippingDelivery from './Pages/Privacy/ShippingDelivery.js';
import RefundPolicy from './Pages/Privacy/RefundPolicy.js';
import ContactPage from './Pages/Privacy/ContactPage.js';
import CigretteBrandPage from './Pages/Home/CigretteBrandPage.jsx';
import {useState, useEffect} from 'react'


// import dotenv from 'dotenv';
// dotenv.config();



function App() {


  const [isOver18, setIsOver18] = useState(false);

  useEffect(() => {
    const userConfirmed = window.confirm('Are you 18+?');

    if (userConfirmed) {
      setIsOver18(true);
    } else {
      alert('You must be 18 or older to access this content.');
    }
  }, []); // The empty dependency array ensures that this effect runs only once on component mount

  

  return (
    <>
      <ToastContainer toastClassName='toastContainerBox' transition={Flip} position='top-center' />
      
      {isOver18 &&
      (
        <Router>
        <DesktopNavigation /> 
        <div className='margin bg-gray-200'>
          <Routes>
            {/*User Routes  */}
            <Route path='/' index element={<HomePage />} />
            <Route path="/login" element={< Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/Detail/type/:cat/:id' element={<ProductDetail />} />
            <Route path='/product/type/:cat' element={<SingleCategory />} /> 
            <Route path='/cigretteBrands' element={<CigretteBrandPage />} />
            <Route path='/vapeBrands' element={<VapeBrandPage/>} />
            <Route path='/tobaccoBrands' element={<TobaccoBrandPage/>} />
            <Route path='/cart' element={<Cart />} />
            <Route path='/wishlist' element={<Wishlist />} />
            <Route path='/checkout' element={<CheckoutForm />} />
            <Route path='/update' element={<UpdateDetails />} />
            <Route path='/paymentsuccess' element={<PaymentSuccess />} />
            <Route path='/forgotpassword' element={<ForgotPasswordForm />} />
            <Route path='/user/reset/:id/:token' element={<AddNewPassword />} />
            <Route path='/privacy-policy' element={<Privacy />} />
            <Route path='/terms-condition' element={<TermsAndCondition />} />
            <Route path='/shipping-delivery' element={<ShippingDelivery />} />
            <Route path='/refund-policy' element={<RefundPolicy />} />
            <Route path='/contact' element={<ContactPage/>} />

            {/* Admin Routes */}
            <Route path="/admin/login" element={< AdminLogin />} />
            <Route path='/admin/register' element={<AdminRegister />} /> 
            <Route path='/admin/home' element={<AdminHomePage />} />
            <Route path='/admin/home/user/:id' element={<SingleUserPage />} />
            <Route path='/admin/home/product/:type/:id' element={<SingleProduct />} />
          </Routes>
        </div>
        <MobileNavigation />
      </Router >
      )};
    </>
  );

}
export default App;