import React, { useState } from "react";
import { Link } from "react-router-dom";


const ContactPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Basic validation
    if (!name || !email || !message) {
      setError("Please fill in all fields.");
      return; 
    }

    try {
      // Simulate API call (replace with actual API call)
      const response = await fetch("https://wss-app-api.vercel.app/api/contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, email, message }),
      });

      if (!response.ok) {
        throw new Error("Message sending failed.");
      }

      // console.log("Message sent successfully:", await response.json());
      setName("");
      setEmail("");
      setMessage("");
      setError("Message sent successfully!");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className=" mx-auto px-4 py-16">
      <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
      <p className="text-lg mb-8">
        Feel free to reach out to us using the form below. We'll get back to
        you as soon as possible.
      </p>
      {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="p-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="p-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
        />
        <textarea
          rows="5"
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="p-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
        />
        <button
          type="submit"
          className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Send Message
        </button>
      </form>
      <p className="text-gray-600 text-sm mt-8">
        You can also reach us at:
        <br />
        <Link href="mailto:contact@yourwebsite.com" className="text-blue-500 hover:underline">
          contact@yourwebsite.com
        </Link>
      </p>
    </div>
  );
};

export default ContactPage;
