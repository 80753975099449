import React from 'react'

const RefundPolicy = () => {
  return (
    <>
    <section class="bg-gray-100 py-10">
    <h2 class="text-2xl font-semibold text-center mb-5">RETURN & REFUND POLICY</h2>

    <div class=" mx-auto max-w-2xl px-4">
      <h3 class="text-xl font-medium mb-2">Return Conditions</h3>
      <ul class="list-disc pl-4 space-y-2">
        <li>You have 30 days to decide if an item is right for you, if not, you can certainly come to us within 30 days of receipt.</li>
        <li>Items returned must be in their unused condition with the original packing. We do not accept a returned item that's worn, damaged, washed or altered in any way.</li>
        <li>We do not accept returned items that were sent back by you directly without checking with us first.</li>
        <li>We do not offer Freight To Collect (FTC) service for the packages returned to us. The returns will be made at your own cost.</li>
      </ul>

      <h3 class="text-xl font-medium mb-2 mt-5">Return Address</h3>
      <p class="text-lg leading-loose mb-4">
        If you wish to return the items to the nearest return address, please contact us, our customer service representative will get back to you within 24 hours.
      </p>
    </div>
  </section>
    </>
  )
}

export default RefundPolicy