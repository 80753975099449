import React from "react";
import { Link } from "react-router-dom";
// import './CopyRight.css';

const CopyRight = (props) => {
  return (
    <>
      <footer className="footer bg-black text-white block content-center align-middle mb-20">
        <div className="footer-content block content-center  ">
          <div className="links flex flex-col ">
            <Link to="/privacy-policy">
              <span className="font-small text-medium hover:text-gray-200">
                Privacy & Policy
              </span>
            </Link>
            <Link to="/terms-condition">
              <span className="font-small text-medium hover:text-gray-200">
                Terms & Conditions
              </span>
            </Link>
            <Link to="/refund-policy">
              <span className="font-small text-medium hover:text-gray-200">
              Refund Policy
              </span>
            </Link>
            <Link to="/ShippingDelivery">
              <span className="font-small text-medium hover:text-gray-200">
              Shipping & Delivery
              </span>
            </Link>
          </div>
          <div className="contact mt-4 text-center content-center">
            <p>Contact Us: info@yourdomain.com</p>
          </div>
        </div>
        <div className="copyright text-center content-center ">
          <p>
            © {new Date().getFullYear()} Your Company Name. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
};

export default CopyRight;
