import { Cigrette, Tobacco, Vape } from '../Assets/Images/Image';


const data = [
    {
        img: Cigrette,
        name: "Cigrette"
    }, 
    {
        img: Vape,
        name: "Vape"
    },
    {
        img: Tobacco,
        name: "Tobacco"
    },

]
export default data