import { Malboro, Benson_Hedges, John_players, Mild_Seven, Sterling, Mayfair,JPS } from "../Assets/Images/Image";

// 'Malboro', 'Benson &  hedges', 'John player', 'Mild seven', 'Sterling', 'Mafair', 'JPS'
const data = [
    {
      img: Malboro,
      name: "Malboro",
    },
    {
      img: Benson_Hedges,
      name: "Benson & Hedges",
    },
    {
      img: John_players,
      name: "John player",
    },
    {
      img: Mild_Seven,
      name: "Mild seven",
    },
    {
      img: Sterling,
      name: "Sterling",
    },
    {
      img:Mayfair,
      name:"Mayfair"

    },
    {
      img:JPS ,
      name:"JPS"
    }
];
export default data;
