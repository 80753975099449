export let payment = 'https://res.cloudinary.com/dxguqzge7/image/upload/v1682838910/payment_mkel3w.jpg';
export let profile = 'https://res.cloudinary.com/dxguqzge7/image/upload/v1682838912/vecteezy_user-avatar-line-style__x94mjc.jpg';
export let EmptyCart = 'https://res.cloudinary.com/dxguqzge7/image/upload/v1682838909/Cart_bk4xgl.jpg';
export let customerReview = "https://res.cloudinary.com/dxguqzge7/image/upload/v1682838911/Review_bm6chw.jpg";
export let Cigrette = "https://res.cloudinary.com/dhvlv7smh/image/upload/v1709245333/cigrette_puewbr.jpg";
export let Vape = "https://res.cloudinary.com/dhvlv7smh/image/upload/v1709245333/vape_vxv7bq.jpg";
export let Tobacco = "https://res.cloudinary.com/dhvlv7smh/image/upload/v1709728741/vape_ot3oqm.jpg";
export let Mild_Seven = 'https://res.cloudinary.com/dhvlv7smh/image/upload/v1709728739/mild_seven_wihcal.jpg';
export let Sterling = "https://res.cloudinary.com/dhvlv7smh/image/upload/v1709728739/sterling_brand_vfnamf.jpg";
export let Mayfair = "https://res.cloudinary.com/dhvlv7smh/image/upload/v1709728739/mayfair_brand_jahq41.jpg";
export let Malboro = "https://res.cloudinary.com/dhvlv7smh/image/upload/v1709728739/malboro_brand_img_nosyn5.png";
export let Lost_Mary = "https://res.cloudinary.com/dhvlv7smh/image/upload/v1709728739/lost_mary_brand_ecwsj6.jpg";
export let John_players = "https://res.cloudinary.com/dhvlv7smh/image/upload/v1709728739/john_play_brand_img_ubcipy.png";
export let John_players_special = "https://res.cloudinary.com/dhvlv7smh/image/upload/v1709728739/jps_brand_xsomfn.jpg";
export let Benson_Hedges = "https://res.cloudinary.com/dhvlv7smh/image/upload/v1709728738/benson_hedges_vwpkq0.jpg";
export let Golden_virginia = "https://res.cloudinary.com/dhvlv7smh/image/upload/v1709728738/golden_virginia_mws8vq.jpg";
export let Elfbar = "https://res.cloudinary.com/dhvlv7smh/image/upload/v1709728740/elfbar_brand_img_w7vus6.jpg";
export let Amber_leaf = "https://res.cloudinary.com/dhvlv7smh/image/upload/v1709728738/amber_leaf_khnbee.jpg";
export let Main = "https://res.cloudinary.com/dhvlv7smh/image/upload/v1709747303/main_rnv1e0.jpg";
export let JPS = "https://res.cloudinary.com/dhvlv7smh/image/upload/v1709728739/jps_brand_xsomfn.jpg"