import classes from './Desktop.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineContacts, AiOutlineShoppingCart, AiFillCloseCircle } from 'react-icons/ai';
import { CgProfile } from 'react-icons/cg';
import { FiLogOut } from 'react-icons/fi';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Badge, Button, Dialog, DialogActions, DialogContent, Menu, MenuItem, Slide, Tooltip, Typography } from '@mui/material';
import { ContextFunction } from '../Context/Context';
import { toast } from 'react-toastify';
import { getCart, getWishList, handleLogOut, handleClickOpen, handleClose, Transition } from '../Constants/Constant'

const DesktopNavigation = () => {

  const { cart, setCart, wishlistData, setWishlistData } = useContext(ContextFunction)
  const [openAlert, setOpenAlert] = useState(false);
  const navigate = useNavigate()
  let authToken = localStorage.getItem('Authorization');
  let setProceed = authToken !== null ? true : false

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [mediaQueryList, setMediaQueryList] = useState(null);

  useEffect(() => {
    const query = window.matchMedia("(max-width: 768px)"); // Adjust breakpoint as needed
    query.addEventListener("change", handleMediaQueryChange);
    setMediaQueryList(query);

    handleMediaQueryChange(query);

    return () => {
      query.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (query) => {
    if (query.matches) {
      // Small screen: Handle visibility and styling on resize
      setIsNavbarOpen(false);
    } else {
      // Large screen: Show navbar by default
      setIsNavbarOpen(true);
    }
  };

  const handleToggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };



  useEffect(() => {
    getCart(setProceed, setCart, authToken)
    getWishList(setProceed, setWishlistData, authToken)
  }, [])


  return (
    <>
      <nav className={` ${classes.nav} flex  dark:bg-gray-900 fixed  w-full transition duration-200 ease-in-out`}>
      <button
        data-collapse-toggle="navbar-default"
        type="button"
        className="flex items-center  w-10 h-10 justify-center text-sm text-gray-500 rounded-lg 
         md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 
         dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-default"
        aria-expanded={isNavbarOpen}
        onClick={handleToggleNavbar}
      >
        <span className="sr-only">Open main menu</span>
        <svg className="w-5 h-5" aria-hidden="true" fill="none" viewBox="0 0 17 14">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
        </svg>
      </button>

      

      <div className={` ${classes.logo}  mt-0 flex`} >
          <Link to='/'>
            <span >WholeSale Smoke</span>
          </Link>
      </div>

        
        <div className={` ${classes.navitems}`}>
          <ul className={`${classes.ul}`}>
            <li className={`${classes.li} nav-links`}>
              <NavLink to='/' className = {`${classes.links}`}>
                <span className='nav-icon-span'>  Home</span>
              </NavLink>
            </li>
            <li className={`${classes.li} nav-links`}>
              <NavLink to='/contact' className = {`${classes.links}`}>
                <span className='nav-icon-span'>  Contact Us</span>
              </NavLink>
            </li>

            <li className={`${classes.li} nav-links`}>
              <Tooltip title='Cart'>
                <NavLink to="/cart" className = {`${classes.links}`}>
                  <span className='nav-icon-span'>Cart    <Badge badgeContent={setProceed ? cart.length : 0}> <AiOutlineShoppingCart className='nav-icon' /></Badge></span>
                </NavLink>
              </Tooltip>
            </li>
            {/* {
              setProceed ?
                <>
                  <li className={`${classes.li} nav-links`}>
                    <Tooltip title='Profile'>
                      <NavLink to='/update' className = {`${classes.links}`}>
                        <span className='nav-icon-span'>  <CgProfile style={{ fontSize: 29, marginTop: 7,marginRight:10 }} /></span>
                      </NavLink>
                    </Tooltip>
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }} onClick={() => handleClickOpen(setOpenAlert)}>
                    <Button variant='contained' className='nav-icon-span' sx={{ marginBottom: 1 }} endIcon={<FiLogOut />}>
                      <Typography variant='button'> Logout</Typography>
                    </Button>
                  </li>
                </>
                :
                <li className="nav-links ">
                  <Tooltip title='Login'>
                    <NavLink to='/login' className = {`${classes.links}`}>
                      <span className='nav-icon-span'>   <CgProfile style={{ fontSize: 29, marginTop: 7 }} /></span>
                    </NavLink>
                  </Tooltip>
                </li>
            } */}
          </ul>
        </div>
        <ul className={`${classes.ulnorm}`}>

        {
          setProceed ?
          <>
                  <li className={`${classes.li} nav-links`}>
                    <Tooltip title='Profile'>
                      <NavLink to='/update' className = {`${classes.links}`}>
                        <span className='nav-icon-span'>  <CgProfile style={{ fontSize: 29, marginTop: 7,marginRight:10 }} /></span>
                      </NavLink>
                    </Tooltip>
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }} onClick={() => handleClickOpen(setOpenAlert)}>
                    <Button variant='contained' className='nav-icon-span' sx={{ marginBottom: 1 }} endIcon={<FiLogOut />}>
                      {/* <Typography variant='button'> Logout</Typography> */}
                    </Button>
                  </li>
                </>
                :
                <li className="nav-links ">
                  <Tooltip title='Login'>
                    <NavLink to='/login' className = {`${classes.links}`}>
                      <span className='nav-icon-span'>   <CgProfile style={{ fontSize: 29, marginTop: 7 }} /></span>
                    </NavLink>
                  </Tooltip>
                </li>
            }
            </ul>
        {/* <div className='links md:hidden flex items-center  rounded-lg ' >
          <Link to='/contact' className = {`${classes.links}`}>
            <AiOutlineContacts   style={{ fontSize: 23, }} />
          </Link>
        </div> */}
       
      </nav >
      {isNavbarOpen && (<div className=" ml-0 fixed w-full  flex  flex-col z-10   bg-gray-200 dark:bg-gray-800 shadow-md transition duration-200 ease-in-out md:bg-transparent md:shadow-none md:hidden" id="navbar-default">   
          <ul className={`  font-medium  flex flex-col  sm:flex sm:flex-col   `}>
            <li>
              <Link to={`cigretteBrands`} className="flex py-2 px-3  text-gray-900 hover:bg-blue-700 rounded sm:bg-transparent sm:text-gray-900 sm:p-0 dark:text-white sm:dark:text-blue-500" aria-current="page">Cigrette</Link>
            </li>
            <li>
              <Link to={`vapeBrands`} className="block py-2 px-3 text-gray-900 rounded hover:bg-blue-700 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Vape</Link>
            </li>
            <li>
              <Link to={`tobaccoBrands`}  className="block py-2 px-3 text-gray-900 rounded hover:bg-blue-700 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Tobacco</Link>
            </li>
            {/* <li>
              <Link to="/about" className="block py-2 px-3 text-gray-900 rounded hover:bg-blue-700 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">About</Link>
            </li>     */}
        </ul>
      </div>
      )}
      <Dialog
        open={openAlert}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      > 
        <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 }, display: 'flex', justifyContent: 'center' }}>
          <Typography variant='h6'>  Do You Want To Logout?</Typography>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Link to="/" className = {`${classes.links}`}>
            <Button variant='contained' endIcon={<FiLogOut />} color='primary' onClick={() => handleLogOut(setProceed, toast, navigate, setOpenAlert)}></Button></Link>
          <Button variant='contained' color='error' endIcon={<AiFillCloseCircle />} onClick={() => handleClose(setOpenAlert)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>

  )
}

export default DesktopNavigation









