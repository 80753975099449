import {  Golden_virginia, Amber_leaf, John_players_special } from '../Assets/Images/Image';

// 'Golden Virginia', 'Ember leaf', 'John player special'

const data = [
    {
        img: Golden_virginia,
        name: "Golden Virginia"
    },
    {
        img: Amber_leaf,
        name: "Ember leaf"
    },
    {
        img: John_players_special,
        name: "John player special"
    },
]
export default data