import { Elfbar, Lost_Mary } from '../Assets/Images/Image';

// 'elfbar', 'Lost mary Elfbar'
const data = [
    {
        img: Elfbar,
        name: "elfbar",
      },
      {
        img: Lost_Mary,
        name: "lost mary Elfbar",
      }, 
]
export default data