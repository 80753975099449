import React from "react";

const ShippingDelivery = () => {
  return (
    <section class="bg-gray-200 py-10">
      <h2 class="text-2xl font-semibold text-center mb-5">
        SHIPPING & DELIVERY
      </h2>

      <div class=" mx-auto max-w-2xl px-4">
        <p class="text-lg leading-loose mb-4">
          We ship all over the world. Just buy and enjoy our great service, we
          ship direct to your door.
        </p>

        <h3 class="text-xl font-medium mb-2">Order Processing</h3>
        <ul class="list-decimal pl-4 space-y-2">
          <li>
            <span class="font-bold mr-2">Received your order request:</span>
            After processing your payment and receiving your order request, we
            will email you to confirm this order. And then, we will prepare the
            items in your order.
          </li>
          <li>
            <span class="font-bold mr-2">Processing time:</span>
            In this part, we kindly ask about 1-4 business days for the quality
            control and arrange a suitable shipment. After the package ships
            out, we will send you an email about the delivery information.
            <small class="text-gray-600">
              Note: In orders with more than one item, the processing time will
              be based on the item with the longest processing time.
            </small>
          </li>
          <li>
            <span class="font-bold mr-2">Shipping time:</span>
            The actual shipping time to destination countries is based on the
            shipping method in your order.
            <ul class="list-disc pl-4 space-y-1 ml-3">
              <li>A: Flat Rate Shipping: 7 - 25 business days</li>
              <li>B: Standard Rate Shipping: 6 - 10 business days</li>
              <li>C: Expedited Rate Shipping: 3 - 7 business days</li>
            </ul>
            <small class="text-gray-600">
              Note: The public holidays, destination countries and couriers will
              be affected the shipping time too.
            </small>
          </li>
          <li>
            <span class="font-bold mr-2">Delivered:</span>
            Total delivery time = Processing time + Shipping time
            <small class="text-">
              According to the feedback, the most packages can deliver
              fast and safely.
            </small>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default ShippingDelivery;
